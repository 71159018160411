<template>
  <td class="px-6 py-3 whitespace-nowrap text-sm font-medium">
    <slot></slot>
  </td>
</template>

<script>
export default {};
</script>

<style></style>
