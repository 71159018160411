<template>
  <tbody class="bg-white divide-y divide-gray-200">
    <slot></slot>
  </tbody>
</template>

<script>
export default {};
</script>

<style></style>
