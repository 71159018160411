<template>
  <input
    class="
      focus:ring-yellow-500 focus:border-yellow-500
      block
      w-full
      shadow-sm
      sm:text-sm
      border-gray-300
      rounded-md
    "
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    modelValue: [String, Number],
  },
  emits: ["update:modelValue"],
};
</script>

<style></style>
