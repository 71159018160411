<template>
  <div class="text-center">
    <undraw-void class="inline-block max-w-20 max-h-20" />
    <p class="text-base mt-5">Nici un rezultat gasit</p>
  </div>
</template>

<script>
import UndrawVoid from "./svgs/UndrawVoid.vue";
export default {
  components: { UndrawVoid },
};
</script>

<style></style>
