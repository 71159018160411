<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th>#</v-table-th>
        <v-table-th>Data</v-table-th>
        <v-table-th>Comanda</v-table-th>
        <v-table-th>Suma</v-table-th>
        <v-table-th>Detalii</v-table-th>
        <v-table-th>Status</v-table-th>
        <v-table-th>Actiuni </v-table-th>
      </tr>
    </v-thead>
    <v-tbody>
      <tr v-for="row in rows" :key="row.id">
        <v-table-td>
          <router-link
            :to="{
              name: 'payments.show',
              params: { paymentId: row.id },
            }"
            class="link"
          >
            # {{ row.id }}
          </router-link>
        </v-table-td>
        <v-table-td>
          {{ formatDate(row.created_at) }}
        </v-table-td>

        <v-table-td>
          <router-link
            :to="{
              name: 'orders.show',
              params: { orderId: row.order.id },
            }"
            class="link"
          >
            # {{ row.order.id }}
          </router-link>
          <br />
          {{ formatPrice(row.order.total) }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.amount) }}
        </v-table-td>
        <v-table-td>
          Procesator: {{ row.gateway?.name }} <br />
          Metoda: {{ row.method?.name }} <br />
          Note: {{ row.description }}
        </v-table-td>

        <v-table-td>
          {{ row.status.name }}
        </v-table-td>
        <v-table-td>
          <modal-update-payment-status
            :paymentId="row.id"
            @update:payment="$emit('update:status', $event, row.id)"
          />
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import VTable from "./ui/VTable.vue";
import VTableTd from "./ui/VTableTd.vue";
import VTableTh from "./ui/VTableTh.vue";
import VTbody from "./ui/VTbody.vue";
import VThead from "./ui/VThead.vue";
import { priceFormatter } from "@/helpers";
import ModalUpdatePaymentStatus from "@/components/ModalUpdatePaymentStatus.vue";

export default {
  components: {
    VTableTd,
    VTable,
    VThead,
    VTableTh,
    VTbody,
    ModalUpdatePaymentStatus,
  },

  props: {
    rows: {
      type: Array,
    },
  },

  emits: ["update:status"],

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
  },
};
</script>
