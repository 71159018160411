<template>
  <thead class="bg-gray-50">
    <slot></slot>
  </thead>
</template>

<script>
export default {};
</script>

<style></style>
