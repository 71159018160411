<template>
  <th
    scope="col"
    class="
      px-6
      py-4
      text-left text-md
      font-medium
      text-gray-700
      capitalize
      tracking-wider
    "
  >
    <slot></slot>
  </th>
</template>

<script>
export default {};
</script>

<style></style>
