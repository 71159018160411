import { PAYMENTS, PAYMENTS_SHOW, PAYMENTS_VENDOR_PAYMENTS, PAYMENTS_TRANSITIONS } from "../endpoints";
import api from "../api";

export default {
    index(query) {
        let url = new URL(PAYMENTS);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    show(payment = null) {
        if (payment === null) return;

        const url = PAYMENTS_SHOW.replace(":payment", payment);

        return api.get(url);
    },

    update(payment, data) {
        if (payment === null) return;

        const url = PAYMENTS_SHOW.replace(":payment", payment);

        return api.put(url, data);
    },

    vendorPaymentsIndex(payment, query) {
        if (payment === null) return;

        let url = new URL(PAYMENTS_VENDOR_PAYMENTS.replace(":payment", payment));

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },

    transitionsIndex(payment, query) {
        if (payment === null) return;

        let url = new URL(PAYMENTS_TRANSITIONS.replace(":payment", payment));

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },
}