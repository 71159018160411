<template>
  <div class="overflow-x-auto">
    <div class="align-middle inline-block min-w-full">
      <div class="overflow-hidden">
        <table class="min-w-full divide-y divide-gray-200">
          <slot></slot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
